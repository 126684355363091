<template>
  <div>
    <div class="mb-2">
      <a-button @click="$router.back()">
        Regresar
      </a-button>
    </div>
    <div class="flex pb-4">
      <div class="w-full">
        <form @keydown="removeError($event.target.id)" @submit.prevent="update(pensum)">
          <h3 class="my-4 text-2xl">{{ isUpdating ? 'Actualizar' : 'Crear' }} pensum</h3>
          <p class="mb-4 text-gray-800 text-2xl">
            Información básica
          </p>
          <div class="space-y-4">
            <a-input
              required
              label="Nombre"
              class="mb-4"
              id="name"
              :error="error('name')"
              v-model="pensum.name"/>

            <a-input
              label="Descrípción"
              required
              class="mb-4"
              id="description"
              :error="error('description')"
              v-model="pensum.description"/>

            <a-select
              label="Especialidad"
              required
              class="mb-4"
              @input="removeError('specialization_id')"
              :loading="loadingSpecializations"
              :error="error('specialization_id')"
              :source="specializations.map(el => ({ label: el.title, value: el.id }))"
              v-model="pensum.specialization_id"/>

            <template v-if="isUpdating">
              <label for="specialization_id" class="block font-medium text-gray-700 mb-2">¿Habilitar?</label>
              <a-switch v-model="pensum.finished" class="mb-4" />
            </template>
          </div>

          <div class="space-x-2 flex" v-if="isUpdating">
            <a-button mode="primary" type="submit" :loading="loading">
              {{ isUpdating ? 'Actualizar cambios' : 'Guardar cambios' }}
            </a-button>

            <a-button outlined :to="{ name: 'pensums' }">
              Cancelar
            </a-button>
          </div>
        </form>

        <h1 class="my-4 text-2xl text-gray-800">
          Semestres {{ pensum.semesters.length ? `(${pensum.semesters.length})` : '' }}
        </h1>

        <div class="space-y-2">
          <semester-row
            v-for="(semester, index) in pensum.semesters"
            :semester="semester"
            @remove="removeSemester"
            @store="handleStoreSemester($event, index)"
            :index="index"
            :is-updating="isUpdating"
            :courses="filteredCourses"
            v-model="pensum.semesters[index]"
            :key="index" #default="{ current }">
            {{ courses.find(course => course.id === current)
            ? courses.find(course => course.id === current).title
            : `${loadingCourses ? 'cargando' : 'Materia no encontrada'}`
            }}
          </semester-row>
<!--          <a-card v-for="(semester, index) in pensum.semesters" :key="index">-->
<!--            <div class="flex items-top">-->
<!--              <div class="w-full md:w-1/6 lg:w-1/6 px-2 inline-block">-->

<!--                <a-input-->
<!--                  required-->
<!--                  label="Número de semestre"-->
<!--                  label-class="font-bold text-gray-700"-->
<!--                  :id="`input_semester_${index}`"-->
<!--                  :error="error(`semesters.${index}.number`, { attribute: 'semestre', value: () => 'materia' })"-->
<!--                  type="number"-->
<!--                  v-model.number="pensum.semesters[index].number"/>-->
<!--              </div>-->
<!--              <div class="w-full md:w-4/6 lg:w-4/6 px-2 inline-block">-->
<!--                <div class="flex">-->
<!--                  <b class="inline-block text-gray-700 mr-2">Materias</b>-->
<!--                  <span class="inline-block text-red-600 font-bold">*</span>-->
<!--                </div>-->
<!--                <a-dropdown placement="left" width="w-96" v-if="(isUpdating && semester.hasOwnProperty('id') || !isUpdating)" keep-open-on-click>-->

<!--                  <button class="py-2">Agregar materia</button>-->

<!--                  <template #menu>-->
<!--                    <a-input placeholder="materia..." is-search  v-model="courseFilter"/>-->
<!--                    <a-dropdown-item class="w-full py-2" v-for="(course, course_index) in filteredCourses" :key="course.id">-->
<!--                      <div class="flex justify-between space-x-2 w-full">-->
<!--                        <label :for="`course_input_${index}_${course_index}`" class="inline-flex space-x-2 items-center cursor-pointer select-none">-->
<!--                          <input-->
<!--                            type="checkbox"-->
<!--                            :id="`course_input_${index}_${course_index}`"-->
<!--                            :value="course.id"-->
<!--                            v-model="pensum.semesters[index].courses_ids"-->
<!--                            class="appearance-none cursor-pointer border checked:ring-2 checked:ring-primary w-4 h-4 checked:border-transparent ring-offset-2 border rounded border-gray-200 active:scale-95 transition-all transform checked:bg-primary">-->
<!--                          <span>-->
<!--                            {{ course.title }}-->
<!--                          </span>-->
<!--                        </label>-->
<!--                      </div>-->
<!--                    </a-dropdown-item>-->
<!--                  </template>-->
<!--                </a-dropdown>-->
<!--                <div class="w-full flex flex-wrap gap-2">-->
<!--                  <a-badge class="bg-green-400" v-for="(id, key) in pensum.semesters[index].courses_ids" :key="id" @close="removeCourseId(index, key)">-->
<!--                    {{ courses.find(course => course.id === id) ? courses.find(course => course.id === id).title : `${loadingCourses ? 'cargando' : 'Materia no encontrada'}` }}-->
<!--                  </a-badge>-->
<!--                </div>-->
<!--                <p style="color: #FF4600;">{{ error(`semesters.${index}.courses_ids`, { attribute: 'materia', value: () => 'semestre' }) }}</p>-->
<!--              </div>-->
<!--            </div>-->
<!--            <div class="px-2 mt-4">-->
<!--              <template v-if="isUpdating && semester.hasOwnProperty('id')">-->
<!--                <a-button @click="updateSemester(semester)" class="mr-2">-->
<!--                  Actualizar semestre-->
<!--                </a-button>-->
<!--              </template>-->
<!--              <template v-else>-->
<!--                <a-button v-if="isUpdating" @click="handleStoreSemester(semester, index)" class="mr-2">-->
<!--                  Crear semestre-->
<!--                </a-button>-->
<!--                <a-button scheme="warning" @click="removeSemester(index)">-->
<!--                  Eliminar semestre-->
<!--                </a-button>-->
<!--              </template>-->
<!--            </div>-->
<!--          </a-card>-->
        </div>

        <div class="flex flex-wrap justify-between space-y-2 items-end">
          <div>
            <a-button outlined @click="addSemester" :loading="loadingSemesters">Agregar semestre</a-button>
          </div>

          <div class="flex space-x-2" v-if="!isUpdating">
            <a-button :loading="loading" mode="primary" @click="isUpdating ? update(pensum) : store(pensum)">
              Guardar
            </a-button>

            <a-button outlined :to="{ name: 'pensums.index' }">
              Cancelar
            </a-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex';
import SemesterRow from './components/semester-row'
export default {
  components: {
    SemesterRow
  },
  data: () => ({
    pensum: {
      name: '',
      description: '',
      specialization_id: null,
      semesters: [],
      finished: false
    },
    isUpdating: false,
    courseFilter: ''
  }),
  computed: {
    ...mapGetters({
      hasError: 'hasError',
      error: 'error'
    }),
    ...mapState({
      pensums: state => state.pensums.pagination.data,
      currentPensumOnState: state => state.pensums.pensum,
      specializations: state => state.specializations.all,
      loadingSpecializations: state => state.specializations.loading,
      loadingCourses: state => state.courses.loading,
      courses: state => state.courses.all,
      loading: state => state.pensums.loading,
      loadingSemesters: state => state.semesters.loading
    }),
    filteredCourses() {
      return this.courses.filter($0 => {
        const matchLowerCase = $0.title.toLowerCase().includes(this.courseFilter.toLowerCase())
        const isNotSelected = !this.pensum.semesters.some($1 => $1.courses_ids.some($2 => $2 === $0.id))
        return matchLowerCase && isNotSelected
      })
    }
  },
  methods: {
    ...mapActions({
      fetchPensum: 'pensums/show',
      store: 'pensums/store',
      update: 'pensums/update',
      removeError: 'removeError',
      fetchSpecializations: 'specializations/fetchSpecializations',
      fetchCourses: 'courses/index',
      updateSemester: 'pensums/updateSemester',
      deleteSemester: 'pensums/deleteSemester',
      storeSemester: 'semesters/store',
    }),
    ...mapMutations({
      setNavbarTitle: 'SET_NAVBAR_TITLE'
    }),
    addSemester() {
      let numbers = this.pensum.semesters.map(it => it.number).sort()
      let current = Math.max(...numbers)
      if (current < 0) {
        current = 0
      }

      let [min, max] = [Math.min(...numbers), Math.max(...numbers)]
      if (min >= 1 && max > 1) {
        let out = Array.from(Array(max - min), (v, i) => i + min)
          .filter(i => !numbers.includes(i))

        if (out.length > 0) {
          current = out[0] - 1
        }
      }

      this.pensum.semesters.push({
        number: current + 1,
        courses_ids: []
      })

      // const container = this.$refs.container

      // function scrollToTargetAdjusted(target, offset) {
      //
      //   const node = document.querySelector(target)

      // console.log(main.scrollTop, main.scrollHeight, container.scrollTop, container.scrollHeight)

      setTimeout(() => {

        const main = document.querySelector('body main')

        // container.scroll({
        //   top: 99999999,
        //   left: 0,
        //   behavior: 'smooth'
        // });
        //
        main.scroll({
          top: main.scrollHeight,
          left: 0,
          behavior: 'smooth'
        });
      }, 1)

      // }

    },
    handleStoreSemester(semester, index) {

      this.storeSemester({
        semester: {
          ...semester,
          pensum_id: this.pensum.id,
        },
        completion: (id, semester) => {
          this.pensum.semesters.splice(index, 1, {
            ...semester,
            courses_ids: []
          })
        }
      })
    },
    removeSemester(index) {
      this.pensum.semesters.splice(index, 1)
    },
    removeCourseId(semester_index, course_index) {
      this.pensum.semesters[semester_index].courses_ids.splice(course_index, 1)
    },
    dropSemester(semester) {
      this.deleteSemester({
        ...semester,
        completion: () => {
          let index = this.pensum.semesters.findIndex(el => el.id === semester.id)
          this.pensum.semesters.splice(index, 1)
        }
      })
    }
  },
  watch: {
    currentPensumOnState(val) {
      this.pensum = {
        ...val,
        semesters: val.semesters.map(semester => ({
          ...semester,
          courses_ids: semester.courses.map(el => el.id)
        }))
      }
    },
    specializations(val) {
      if (this.isUpdating) {
        let found = val.find(specialization => specialization.id === this.pensum.specialization_id)
        if (found) {
          this.pensum.specialization_id = found.id
        }
      }
    }
  },
  created() {
    const id = this.$route.query.update
    this.setNavbarTitle(['Listado de pensums', 'Crear pensum'])
    this.fetchSpecializations({ limit: 0 })
    this.fetchCourses({ limit: 0, enabled: true })
    if (id) {
      this.isUpdating = true

      const found = this.pensums.find(pensums => pensums.id === parseInt(id))
      if (found) {
        this.pensum = {
          ...found,
          semesters: found.semesters.map(semester => ({
            ...semester,
            courses_ids: semester.courses.map(el => el.id)
          }))
        }
        this.setNavbarTitle(['Listado de pensums', found.name, 'Editar pensum'])
      } else {
        this.fetchPensum(id)
      }
    }
  }
}
</script>
